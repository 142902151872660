import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline, Box, Container, Typography, Grid, Paper, TextField, Button } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, LineChart, Line, XAxis, YAxis, CartesianGrid } from 'recharts';

// Import Open Sans font
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/700.css';

const theme = createTheme({
  palette: {
    primary: { main: '#151515' },
    secondary: { main: '#323232' }, // used for buttons and some larger text
    background: { default: '#F2F4F7', paper: '#EEEEEE' }, // paper is used for the cards
  },
  typography: {
    fontFamily: '"Open Sans", sans-serif',
    h1: { fontFamily: '"Major Mono Display", monospace' },
    h5: { fontWeight: 700, textAlign: 'center' },
  },
});

const holdings = [
    // Deep purple for AI
  { name: 'Renewable Energy Developments', value: 7, color: '#22A39F' },  // Green for Renewable Energies
  { name: 'Stocks', value: 29, color: '#A6F0C6' },  
  { name: 'Cash', value: 11, color: '#F3EFE0' },
  { name: 'Technology and AI', value: 18, color: '#D7385E' }, 
  { name: 'Real Estate Developments', value: 35, color: '#E48257' },
  
];

const growthData = [
  { quarter: 'Q1 2024', value: 50 },
  { quarter: 'Q2 2024', value: 160 },
  { quarter: 'Q3 2024', value: 190 },
  { quarter: 'Q4 Forecast 2024', value: 280 },
  { quarter: 'Q1 Forecast 2025', value: 350 },
  { quarter: 'Q2 Forecast 2025', value: 420 },
  { quarter: 'Q3 Forecast 2025', value: 460 },
  { quarter: 'Q4 Forecast 2025', value: 500 },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <Box sx={{ 
        bgcolor: data.color, 
        p: 1, 
        border: '1px solid #ccc', 
        borderRadius: '10px',
        color: theme => theme.palette.getContrastText(data.color)
      }}>
        <Typography variant="body2">{data.name}</Typography>
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
          {data.value}%
        </Typography>
      </Box>
    );
  }
  return null;
};

// Custom position function for the tooltip
const CustomTooltipPosition = ({ x, y }) => ({
  x,
  y: y - 70, // Adjust this value to fine-tune the vertical position
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        {/* Hero Section */}
        <Box sx={{ bgcolor: 'primary.main', color: 'background.default', py: 6, mb: 4 }}>
          <Container maxWidth="lg">
            <Typography variant="h1" sx={{ mb: 0, textAlign: 'center', fontFamily: '"Major Mono Display", monospace', fontWeight: 'bold' }}>
              <span style={{ color: '#EDC988' }}>M</span>
              <span style={{ color: 'inherit' }}>onune</span>
            </Typography>
            <Typography variant="h5" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
              Innovation Holdings
            </Typography>
          </Container>
        </Box>

        {/* Main Content */}
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            {/* Assets Under Management */}
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ p: 2, backgroundColor: 'background.paper', height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5" sx={{ mb: 0, color: 'primary.main' }}>
                  Assets Under Management
                </Typography>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography 
                    variant="h3" 
                    sx={{ 
                      color: 'secondary.main',
                      fontWeight: 700,
                      letterSpacing: '-0.02em'
                    }}
                  >
                    $190B
                  </Typography>
                </Box>
              </Paper>
            </Grid>

            {/* Holdings Pie Chart */}
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ p: 2, backgroundColor: 'background.paper', height: '100%' }}>
                <Typography variant="h5" sx={{ mb: 2, color: 'primary.main' }}>
                  Holdings Distribution
                </Typography>
                <Box sx={{ height: 200 }}> {/* Fixed height for the chart container */}
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={holdings}
                        cx="50%"
                        cy="50%"
                        innerRadius={50}
                        outerRadius={100}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                      >
                        {holdings.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                      </Pie>
                      <Tooltip 
                        content={<CustomTooltip />} 
                        position={({ x, y }) => ({ x, y: y - 70 })}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </Paper>
            </Grid>

       
            <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 2, backgroundColor: 'background.paper', height: '100%' }}>
              <Typography variant="h5" sx={{ mb: 2, color: 'primary.main' }}>
                AUM Over Time
              </Typography>
              <Box sx={{ height: 200 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={growthData}>
                    <XAxis 
                      dataKey="quarter" 
                      tickFormatter={(value) => value.split('Q')[0]}
                    />
                    <YAxis />
                    <Tooltip />
                    <Line 
                      type="monotone" 
                      dataKey="value" 
                      stroke="#8884d8" 
                      strokeWidth={2} 
                      strokeDasharray={(dataPoint) => 
                        dataPoint.quarter.toLowerCase().includes('Forecast') ? "5 5" : "0"
                      }
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
              </Paper>
            </Grid>


            {/* Newsletter */}
            {/* <Grid item xs={12}>
              <Paper elevation={3} sx={{ p: 3, backgroundColor: 'background.paper' }}>
                <Typography variant="h5" sx={{ mb: 1, color: 'primary.main' }}>
                  Subscribe to Our Newsletter
                </Typography>
                <Box component="form" sx={{ display: 'flex', gap: 2 }}>
                  <TextField
                    variant="outlined"
                    placeholder="Your email"
                    fullWidth
                    sx={{ backgroundColor: 'background.default' }}
                  />
                  <Button variant="contained" color="secondary">
                    Subscribe
                  </Button>
                </Box>
              </Paper>
            </Grid> */}
          </Grid>
        </Container>

        {/* Footer */}
        <Box component="footer" sx={{ bgcolor: 'primary.main', color: 'background.default', py: 3, mt: 'auto' }}>
          <Container maxWidth="lg">
            <Typography variant="body2" align="center">
              © 2024 Monune Holding. All rights reserved.
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
